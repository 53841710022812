import * as React from "react"
import Layout from "../components/Layout";
import styled from "styled-components";
import Wrapper from "../components/Wrapper";
import ContactPrompt from "../components/ContactPrompt";
import PrimaryButton from "../components/PrimaryButton";
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const NotFound = styled.div`
    max-width:600px;
    margin:150px 0 100px;
    h1{
        @media (min-width: ${props => props.theme.size.medium}){
            font-size:4em;
        }
    }
    div{
        margin-bottom:40px;
    }
`

const NotFoundPage = ({ data }) => (
    <Layout>
        <HelmetDatoCms> 
            <title>{data.datoCmsNotfoundpage.siteseo && data.datoCmsNotfoundpage.siteseo.title}</title> 
            <meta name="description" content={data.datoCmsNotfoundpage.siteseo && data.datoCmsNotfoundpage.siteseo.description}/> 
        </HelmetDatoCms> 
        <Wrapper>
            <NotFound>
                <h1>{data.datoCmsNotfoundpage.notfoundtitle}</h1>
                <div dangerouslySetInnerHTML={{__html: data.datoCmsNotfoundpage.notfoundmessage}}/>
                <PrimaryButton to="/" primaryButtonText="TILBAGE TIL FORSIDEN" />
            </NotFound>  
        </Wrapper>
        {
            data.datoCmsNotfoundpage.blocks.map((block) => (
            <section key={block.id}>
                {block.model.apiKey === 'contact' && <ContactPrompt title={block.title} description={block.description} number={block.phoneNumber} mail={block.eMail}/>}
            </section>
            ))
        }
    </Layout>
)

export default NotFoundPage

export const query = graphql`
    query Query{
        datoCmsNotfoundpage{
            siteseo {
                title
                description
            }
            notfoundtitle
            notfoundmessage
            blocks {
                model { apiKey }
                id
                title
                description
                phoneNumber
                eMail
            }
        }
    }
`